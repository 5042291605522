const XIcon = () => (
  <svg viewBox="0 0 25 25">
    <title>X icon</title>
    <path
      d="M14.735 10.704 23.473.547h-2.071l-7.587 8.82-6.06-8.82H.765L9.93 13.883.766 24.535h2.07l8.013-9.314 6.4 9.314h6.989l-9.504-13.831Zm-2.837 3.297-.928-1.328L3.582 2.106h3.181l5.962 8.528.928 1.328 7.75 11.085h-3.18L11.897 14Z"
      fill="currentColor"
    />
  </svg>
);

export default XIcon;
