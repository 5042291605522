const FacebookIcon = () => (
  <svg fill="none" viewBox="0 0 24 25">
    <title>Facebook icon</title>
    <path
      d="M21.818.547H2.182A2.181 2.181 0 0 0 0 2.729v19.636c0 1.206.976 2.182 2.182 2.182H13.09v-9.818H9.818v-3.273h3.273V9.699c0-3.328 1.621-4.788 4.386-4.788 1.325 0 2.025.098 2.357.142v3.13h-1.886c-1.174 0-1.584.62-1.584 1.874v1.399h3.44l-.466 3.273h-2.974v9.818h5.454A2.181 2.181 0 0 0 24 22.365V2.73A2.182 2.182 0 0 0 21.818.547Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIcon;
