const InstagramIcon = () => (
  <svg fill="none" viewBox="0 0 24 25">
    <title>Instagram icon</title>
    <path
      d="M6.998.547C3.139.547 0 3.689 0 7.549v10c0 3.859 3.142 6.998 7.002 6.998h10c3.859 0 6.998-3.142 6.998-7.002v-10c0-3.86-3.142-6.998-7.002-6.998h-10Zm12.002 4a1 1 0 1 1 0 2 1 1 0 0 1 0-2Zm-7 2c3.309 0 6 2.69 6 6 0 3.309-2.691 6-6 6s-6-2.691-6-6c0-3.31 2.691-6 6-6Zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
      fill="currentColor"
    />
  </svg>
);

export default InstagramIcon;
