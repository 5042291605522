const LinkedInIcon = () => (
  <svg fill="none" viewBox="0 0 24 25">
    <title>LinkedIn icon</title>
    <path
      d="M21.143.547H2.857A2.858 2.858 0 0 0 0 3.404V21.69a2.858 2.858 0 0 0 2.857 2.857h18.286A2.858 2.858 0 0 0 24 21.69V3.404A2.858 2.858 0 0 0 21.143.547ZM7.429 9.69v10.857H4V9.69h3.429ZM4 6.53c0-.8.686-1.412 1.714-1.412 1.029 0 1.675.612 1.715 1.412 0 .8-.64 1.445-1.715 1.445C4.686 7.975 4 7.33 4 6.53Zm16 14.017h-3.429v-5.714c0-1.143-.571-2.286-2-2.309h-.045c-1.383 0-1.955 1.177-1.955 2.309v5.714H9.143V9.69h3.428v1.463s1.103-1.463 3.32-1.463C18.16 9.69 20 11.25 20 14.41v6.137Z"
      fill="currentColor"
    />
  </svg>
);

export default LinkedInIcon;
