const TiktokIcon = () => (
  <svg fill="none" viewBox="0 0 25 25">
    <title>TikTok icon</title>
    <path
      clipRule="evenodd"
      d="M3.23828 0.546875H21.2383C22.8951 0.546875 24.2383 1.89002 24.2383 3.54688V21.5469C24.2383 23.2037 22.8951 24.5469 21.2383 24.5469H3.23828C1.58143 24.5469 0.238281 23.2037 0.238281 21.5469V3.54688C0.238281 1.89002 1.58143 0.546875 3.23828 0.546875ZM15.8901 4.27648C16.0929 5.37208 16.7433 6.31168 17.6439 6.89908C18.2709 7.30828 19.0197 7.54647 19.8225 7.54647V10.4217C18.3315 10.4217 16.9497 9.94468 15.8223 9.13527V14.9781C15.8223 17.8965 13.4481 20.2701 10.5303 20.2701C9.40228 20.2701 8.35708 19.9149 7.49728 19.3113C6.13288 18.3537 5.23828 16.7691 5.23828 14.9787C5.23828 12.0609 7.61248 9.68667 10.5303 9.68667C10.7727 9.68667 11.0103 9.70648 11.2437 9.73828V12.6735C11.0175 12.6027 10.7793 12.5619 10.5303 12.5619C9.19768 12.5619 8.11348 13.6461 8.11348 14.9787C8.11348 15.9063 8.64028 16.7127 9.40948 17.1177C9.74488 17.2941 10.1259 17.3955 10.5303 17.3955C11.8323 17.3955 12.8937 16.3599 12.9423 15.0693L12.9471 3.54688H15.8223C15.8223 3.79588 15.8463 4.03948 15.8901 4.27648Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default TiktokIcon;
