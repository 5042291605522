import { tokens } from '@dvd/tokens';

type WistiaLogoProps = {
  /** Should show just the mark? */
  iconOnly?: boolean;

  /** Should render a logo optimized for smaller use-cases? */
  isOptical?: boolean;

  /** Color scheme for the composite logo.
   *
   * - full: brand blue logotype w/ dark blue mark
   * - dark: logotype & mark both dark blue
   * - light: logotype & mark both white
   */
  color?: 'dark' | 'full' | 'light';

  /** Optional class name, nesc. for extending as a styled-component */
  className?: string;
};

const computedFills = (color) => {
  // all blue800
  if (color === 'dark') {
    return {
      markFill: tokens.colors.blue800,
      logotypeFill: tokens.colors.blue800,
    };
  }
  // all white
  if (color === 'light') {
    return {
      markFill: tokens.colors.white,
      logotypeFill: tokens.colors.white,
    };
  }
  // brand blue + darkest blue
  return {
    markFill: tokens.colors.blue500,
    logotypeFill: tokens.colors.blue800,
  };
};

// 'optical' versions have a slightly different marks and adjusted logotype
// paths.
//
// - The outer shape of the flags remains consistent, but the space between
// the two flag elements is slightly larger in the smaller optical size, to
// better balance with the spacing of the wordmark.
//
// - And, the logotype letter paths are slightly thinner for enhanced
// legibility.
const computedPaths = (isOptical) => {
  if (isOptical) {
    return {
      markPath:
        'm16.2 23h-5.1c-1.6 0-3.1.4-4.2 1.6l-6.7 7.7c4.9.3 10 .3 13.6.3 18.3 0 21.2-11 21.3-16.5-1.6 2-6.4 6.9-18.9 6.9zm16.6-17.7c-.1.9-.6 4.8-11.6 4.8-8.9 0-12.5 0-21.3-.2l6.6 7.6c1.3 1.5 3.1 2 5.1 2.1 2.2 0 5.2.1 5.6.1 12.3 0 17.5-5.7 17.5-9.8.1-2.2-.6-3.6-1.9-4.6z',
      logotypePath:
        'm66 9.7h6.5v15.5c0 1.7-.4 3.2-1.1 4.4s-1.6 2-2.8 2.6-2.5.8-4 .8-2.8-.3-4-.8c-1.2-.6-2.1-1.4-2.8-2.6-.1-.1-.1-.3-.2-.4-.1.1-.1.3-.2.4-.7 1.2-1.6 2-2.8 2.6s-2.5.8-4 .8-2.8-.3-3.9-.8c-1.2-.6-2.1-1.4-2.8-2.6s-1-2.6-1-4.4l-.2-15.5h6.5v14.3c0 1 .2 1.7.7 2.1s1.1.6 1.8.6 1.4-.2 1.9-.6.8-1.1.8-2.1v-14.3h2.4 1.7 2.4v14.3c0 1 .3 1.7.8 2.1s1.2.6 1.9.6 1.3-.2 1.8-.6c.4-.4.6-1.1.6-2.1zm15.9-8.3c-.5-.3-1.1-.4-1.8-.4s-1.3.1-1.8.4-1 .7-1.3 1.2-.5 1.1-.5 1.7c0 1 .3 1.8 1 2.4s1.6.9 2.6.9c.7 0 1.3-.1 1.8-.4.6-.3 1-.7 1.3-1.2s.5-1.1.5-1.8-.2-1.2-.5-1.7c-.3-.4-.7-.8-1.3-1.1zm-5.1 31.1h6.5v-22.5h-6.5zm21.6-10.5c-.3-.5-.8-1-1.2-1.5-.4-.4-.9-.8-1.4-1.3-.4-.4-.7-.7-1-1-.2-.3-.3-.6-.3-1 0-.6.3-1 1-1.3s1.5-.4 2.5-.4h1l-.6-6c-.6-.1-1.2-.1-1.8-.1-1.6 0-3.1.2-4.6.6-1.4.4-2.6 1.1-3.5 2.1s-1.4 2.3-1.4 4c0 1.2.2 2.2.7 3.1s1.1 1.7 1.8 2.5c.4.4.8.8 1.2 1.2s.8.8 1 1.1.3.6.3 1c0 .6-.3 1.1-1 1.4s-1.6.5-2.9.5c-.5 0-.9 0-1.1-.1l.8 6c.6.2 1.1.2 1.8.2 1.9 0 3.5-.3 5-.8s2.7-1.3 3.6-2.4 1.3-2.4 1.3-4.1c0-.7-.1-1.4-.3-2.1-.3-.6-.5-1.1-.9-1.6zm11.9 4.1c-.5-.5-.7-1.2-.7-2.1v-8.3h4.7v-5.7h-4.7v-5.7l-6.5 1.9v19.9c0 2.3.5 4.1 1.4 5.2 1 1.1 2.4 1.7 4.3 1.7 1 0 1.9-.1 2.8-.2s1.6-.3 2.3-.6l1.2-5.6c-.7.2-1.6.3-2.6.3-1.1-.1-1.8-.3-2.2-.8zm8.1 6.4h6.5v-22.5h-6.5zm5.1-31.1c-.5-.3-1.1-.4-1.8-.4s-1.3.1-1.8.4-1 .7-1.3 1.2-.5 1.1-.5 1.7c0 1 .3 1.8 1 2.4s1.6.9 2.6.9c.7 0 1.3-.1 1.8-.4.6-.3 1-.7 1.3-1.2s.5-1.1.5-1.8-.2-1.2-.5-1.7c-.3-.4-.7-.8-1.3-1.1zm28.9 8.6v22.5h-6.5v-3.7c-.7 1.1-1.5 2-2.5 2.7-1.4 1-3.1 1.5-5 1.5s-3.6-.5-5-1.5-2.5-2.4-3.3-4.2-1.2-3.8-1.2-6.1.4-4.3 1.2-6c.8-1.8 1.9-3.2 3.3-4.2s3.1-1.5 5-1.5 3.6.5 5 1.5c1 .7 1.8 1.6 2.5 2.7v-3.7zm-7 14c.5-.8.7-1.8.7-2.8s-.2-2-.7-2.8c-.4-.8-1-1.5-1.8-1.9-.8-.5-1.6-.7-2.6-.7-.9 0-1.8.2-2.6.7s-1.4 1.1-1.8 1.9c-.5.8-.7 1.7-.7 2.8 0 1 .2 2 .7 2.8s1.1 1.5 1.8 1.9c.8.5 1.6.7 2.6.7.9 0 1.8-.2 2.6-.7.8-.4 1.4-1.1 1.8-1.9z',
    };
  }
  return {
    markPath:
      'm16.4 22.7h-5.3c-1.6 0-3.1.7-4.2 1.9l-6.7 7.7c4.9.3 9.9.3 13.5.3 18.7 0 21.3-11.5 21.3-16.9-1.6 2-6.2 7-18.6 7zm16.3-17.4c-.1.9-.6 4.8-11.5 4.8-8.9 0-12.4 0-21.2-.2l6.5 7.5c1.3 1.5 3.1 2.3 5 2.3 2.2 0 5.1.1 5.6.1 11.2 0 17.5-5.1 17.5-10.1.1-1.9-.6-3.4-1.9-4.4z',
    logotypePath:
      'm71.3 9.8v15.4c0 1.7-.4 3.1-1.1 4.3-.7 1.1-1.7 2-2.9 2.6s-2.6.8-4.1.8-2.9-.3-4.1-.9-2.2-1.4-2.9-2.6c-.1-.1-.2-.3-.2-.4-.1.1-.2.3-.2.4-.7 1.1-1.7 2-2.9 2.6s-2.6.9-4.1.9-2.8-.3-4-.8c-1.2-.6-2.1-1.4-2.8-2.6s-1-2.6-1-4.3l-.2-15.4h7.5v13.9c0 .8.2 1.4.6 1.7s.9.4 1.4.4 1-.2 1.5-.5c.4-.3.7-.9.7-1.7v-13.8h2.9 1.7 2.9v13.9c0 .8.2 1.4.7 1.7.4.3.9.5 1.5.5.5 0 1-.1 1.4-.4s.6-.9.6-1.7v-14zm2.5 0v22.6h7.5v-22.6zm5.8-1.9c.6-.3 1.1-.7 1.4-1.3.4-.6.5-1.2.5-2 0-.7-.2-1.4-.5-1.9-.3-.6-.8-1-1.4-1.3-.6-.2-1.3-.4-2-.4-.8 0-1.4.2-2.1.5-.6.3-1.1.7-1.4 1.3-.4.6-.5 1.2-.5 1.9 0 1.1.4 2 1.2 2.7s1.7 1 2.9 1c.6-.1 1.3-.2 1.9-.5zm9 2c-1.4.4-2.6 1.1-3.6 2.2-1 1-1.4 2.4-1.4 4.2 0 1.1.2 2.1.5 3 .4.9.8 1.7 1.4 2.4.2.3.6.7 1 1.1s.6.7.8 1c.2.2.3.5.3.7 0 .5-.4.9-1.2 1.1s-1.7.3-2.5.3c-.1 0-.2 0-.3 0l1 7.1h1.5c1.9 0 3.7-.3 5.2-.9s2.7-1.4 3.6-2.6 1.3-2.6 1.3-4.3c0-.7-.1-1.3-.2-1.9s-.3-1.1-.6-1.6c-.2-.5-.5-.9-.8-1.3s-.8-.8-1.4-1.4c-.4-.3-.7-.6-.9-.8s-.3-.5-.3-.7c0-.5.3-.8.8-1s1.2-.3 1.9-.3h.8l-.7-7c-.5-.1-1-.1-1.6-.1-1.5.2-3.1.4-4.6.8zm19.9 16c-1 0-1.7-.2-2.2-.7-.4-.5-.6-1.2-.6-2.1v-6.7h4.3v-6.6h-4.3v-5.4l-7.5 1.9v19.2c0 2.5.5 4.4 1.4 5.6 1 1.2 2.5 1.9 4.6 1.9 1 0 1.9-.1 2.8-.2s1.7-.3 2.3-.6l1.4-6.4c-.6 0-1.3.1-2.2.1zm4.2-16.1v22.6h7.5v-22.6zm5.8-1.9c.6-.3 1.1-.7 1.4-1.3.4-.6.5-1.2.5-2 0-.7-.2-1.4-.5-1.9-.3-.6-.8-1-1.4-1.3s-1.3-.5-2-.5c-.8 0-1.4.2-2.1.5-.6.3-1.1.7-1.4 1.3-.4.6-.5 1.2-.5 1.9 0 1.1.4 2 1.2 2.7s1.7 1 2.9 1c.6 0 1.2-.1 1.9-.4zm27.9 1.9v22.6h-7.4v-2.3c-.4.4-.9.8-1.4 1.2-1.5 1.1-3.2 1.6-5.1 1.6s-3.7-.5-5.2-1.6-2.7-2.5-3.5-4.3-1.2-3.8-1.2-6 .4-4.1 1.2-5.9 2-3.2 3.5-4.3 3.2-1.6 5.2-1.6 3.7.5 5.1 1.6c.5.4.9.8 1.4 1.2v-2.2zm-7.2 11.3c0-.8-.2-1.6-.6-2.2-.4-.7-.9-1.2-1.6-1.6-.6-.4-1.4-.6-2.1-.6s-1.5.2-2.1.6-1.2.9-1.6 1.6-.6 1.4-.6 2.2.2 1.5.6 2.2.9 1.2 1.6 1.6c.6.4 1.4.6 2.1.6s1.5-.2 2.1-.6c.7-.4 1.2-.9 1.6-1.6.4-.6.6-1.4.6-2.2z',
  };
};

/**
 * Renders an SVG Logo — that will take up the full space provided it by it's
 * container.
 *
 * _Note_: 'optical' versions are better for smaller use-cases, as they have an
 * adjusted mark and logotype for enhanced legibility.
 *
 * _Current Recommendation_: show the optical version when rendering the logo
 * at sizes smaller than 48px / 3rem.
 * */
const WistiaLogo = ({
  iconOnly = false,
  isOptical = false,
  color = 'full',
  className,
}: WistiaLogoProps) => {
  const viewBox = iconOnly ? '0 0 36 34' : '0 0 154 34';
  const { markFill, logotypeFill } = computedFills(color);
  const { markPath, logotypePath } = computedPaths(isOptical);

  return (
    <svg className={className} role="img" viewBox={viewBox}>
      <title>Wistia</title>
      <path d={markPath} fill={markFill} />
      {!iconOnly && <path d={logotypePath} fill={logotypeFill} />}
    </svg>
  );
};

export default WistiaLogo;
