'use client';

import { styled } from 'styled-components';
import cs from 'classnames';
import Link from 'next/link';
import footerData from '@content/navigation/footerData';
import * as Styled from '@lib/styles';
import Section from '@dvd/components/Section';
import WistiaLogo from '@dvd/components/WistiaLogo';
import InstagramIcon from '@dvd/components/shareIcons/InstagramIcon';
import LinkedInIcon from '@dvd/components/shareIcons/LinkedInIcon';
import FacebookIcon from '@dvd/components/shareIcons/FacebookIcon';
import TiktokIcon from '@dvd/components/shareIcons/TiktokIcon';
import XIcon from '@dvd/components/shareIcons/XIcon';

type FooterProps = {
  /**
   * Theme color option
   *  - light
   *  - dark
   */
  theme?: 'dark' | 'light';
};

const SOCIAL_LINKS = [
  {
    label: 'X',
    href: 'https://twitter.com/wistia',
    icon: <XIcon />,
  },
  {
    label: 'TikTok',
    href: 'https://www.tiktok.com/@wistia',
    icon: <TiktokIcon />,
  },
  {
    label: 'Instagram',
    href: 'https://www.instagram.com/wistia',
    icon: <InstagramIcon />,
  },
  {
    label: 'LinkedIn',
    href: 'https://www.linkedin.com/company/wistia',
    icon: <LinkedInIcon />,
  },
  {
    label: 'Facebook',
    href: 'https://www.facebook.com/wistia',
    icon: <FacebookIcon />,
  },
];

const FooterNavListItem = styled.li`
  list-style: none;

  & p {
    margin: 0;
  }

  a {
    font-weight: ${({ theme }) => theme.typography.fontWeight.body};
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: text-decoration-color 333ms ease-in-out;
  }

  a,
  a:active,
  a:visited,
  a:hover {
    color: inherit;
  }

  a:hover {
    text-decoration-color: currentcolor;
  }
`;

const FooterContainer = styled(Section)`
  padding-block: ${({ theme }) => theme.spacing[2]};

  ${({ theme }) => theme.mq.minWidth.large} {
    padding-block: ${({ theme }) => theme.spacing[4]};
  }
`;

const FooterSections = styled.div`
  display: grid;
  ${({ theme }) => theme.grid.columns.small};

  ${({ theme }) => theme.mq.minWidth.medium} {
    grid-template-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
  }
  ${({ theme }) => theme.mq.minWidth.large} {
    ${({ theme }) => theme.grid.columns.large};
  }
`;

const FooterNavList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  row-gap: ${({ theme }) => theme.spacing[0.75]};
`;

const FooterNavSection = styled.nav`
  grid-column: auto / span 1;

  &.Product {
    grid-column: 1 / span 2;
  }

  &.Learning,
  &.Video,
  &.Support,
  &.Company {
    margin-top: ${({ theme }) => theme.spacing[4]};
  }
  ${({ theme }) => theme.mq.minWidth.medium} {
    grid-column: auto / span 1;

    &.Product {
      grid-column: 1 / span 2;
    }

    &.Video,
    &.Support,
    &.Company {
      margin-top: ${({ theme }) => theme.spacing[4]};
    }

    &.Learning {
      margin-top: unset;
    }
  }

  ${({ theme }) => theme.mq.minWidth.large} {
    grid-column: auto / span 2;

    &.Product {
      grid-column: 1 / span 4;
    }

    &.Video,
    &.Support,
    &.Company {
      margin-top: unset;
    }
  }

  &.Product > ${FooterNavList} {
    columns: 2;
    display: block;
  }

  &.Product ${FooterNavListItem} {
    /** To mimic the 12px row gap */
    padding-block: 6px;
  }

  &.Product ${FooterNavListItem} a {
    vertical-align: top;
  }
`;

const FooterLogo = styled.div`
  padding-block: ${({ theme }) => theme.spacing[2]};
  text-align: center;
  text-wrap: balance;

  ${({ theme }) => theme.mq.minWidth.large} {
    padding-block: ${({ theme }) => theme.spacing[4]};
  }

  p {
    margin: 0;
  }
`;

const Logo = styled(WistiaLogo)`
  margin-block-end: ${({ theme }) => theme.spacing[1]};

  /** custom per design */
  max-width: 132px;
  width: 100%;
`;

const TrustCenter = styled.abbr`
  display: block;
  font-size: ${({ theme }) => theme.typography.fontSize.link};
  font-weight: ${({ theme }) => theme.typography.fontWeight.link};
  text-decoration: none;
  text-align: center;
  margin-block-end: ${({ theme }) => theme.spacing[1]};

  ${({ theme }) => theme.mq.minWidth.medium} {
    text-align: start;
    margin-block-end: 0;
  }
`;

const FooterLinks = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }

  a {
    color: currentcolor;
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ShareLinks = styled.div<{ $theme: string }>`
  color: ${({ theme, $theme }) =>
    $theme === 'light' ? theme.colors.blue500 : 'inherit'};
  display: flex;
  margin-block-start: ${({ theme }) => theme.spacing[2]};
  gap: ${({ theme }) => theme.spacing[1.25]};

  svg {
    height: ${({ theme }) => theme.spacing[1.5]};
    width: ${({ theme }) => theme.spacing[1.5]};
  }

  ${({ theme }) => theme.mq.minWidth.medium} {
    margin-block-start: 0;
  }
`;

const ShareLinkTitle = styled.span.attrs({
  className: 'visually-hidden',
})``;

const CURRENT_YEAR = new Date().getFullYear();

const Footer = ({ theme = 'light' }: FooterProps) => (
  <footer>
    <FooterContainer colorScheme={theme}>
      <FooterSections>
        {footerData.map((section) => (
          <FooterNavSection
            key={section.heading}
            className={cs(section.heading)}
          >
            <h5>{section.heading}</h5>
            <FooterNavList>
              {section.links.map((link) => (
                <FooterNavListItem key={link.label}>
                  <Styled.BodySm as={Link} href={link.href}>
                    {link.label}
                  </Styled.BodySm>
                </FooterNavListItem>
              ))}
            </FooterNavList>
          </FooterNavSection>
        ))}
      </FooterSections>
      <FooterLogo>
        <Logo color={theme === 'light' ? 'full' : 'light'} />
        <p>Your complete video marketing platform</p>
      </FooterLogo>
      <TrustCenter title="Wistia is certified compliant SOC 2® - SOC for Service Organizations">SOC 2 Certified</TrustCenter>
      <FooterLinks>
        <Styled.BodySm>
          ©2001–{CURRENT_YEAR} All rights reserved.{' '}
          <Link href="/site-map">Site Map</Link>,{' '}
          <Link href="/privacy">Privacy</Link>, and{' '}
          <Link href="/terms">Terms</Link>
        </Styled.BodySm>

        <ShareLinks $theme={theme}>
          <p className="visually-hidden">Follow us on social media!</p>
          {SOCIAL_LINKS.map((link) => {
            return (
              <Link
                key={link.label}
                href={link.href}
                title={`Follow us on ${link.label}`}
              >
                {link.icon}
                <ShareLinkTitle>{`Follow us on ${link.label}`}</ShareLinkTitle>
              </Link>
            );
          })}
        </ShareLinks>
      </FooterLinks>
    </FooterContainer>
  </footer>
);

export default Footer;
