import { colors, colorSchemes, gradients } from './color';

/**
 * Important Note: the tokens assume 1rem == 16px for design purposes!
 *
 * Most used conversions:
 * 0.25 - 4px
 * 0.5  - 8px
 * 0.75 - 12px
 * 1    - 16px
 * 1.25 - 20px
 * 1.5  - 24px
 */
const spacing = {
  0.25: '0.25rem',
  0.5: '0.5rem',
  0.75: '0.75rem',
  1: '1rem',
  1.25: '1.25rem',
  1.5: '1.5rem',
  2: '2rem',
  2.25: '2.25rem',
  2.5: '2.5rem',
  3: '3rem',
  3.75: '3.75rem',
  4: '4rem',
  5: '5rem',
  5.25: '5.25rem',
  6: '6rem',
  7: '7rem',
  8: '8rem',
  9: '9rem',
  10: '10rem',
};

const breakpoints = {
  xl: '1440px',
  large: '1025px',
  medium: '721px',
  small: '541px',
  xs: '391px',
};

const minWidthMediaQuery = (minWidth: string) =>
  `@media screen and (min-width: ${minWidth})`;

const mq = {
  minWidth: {
    xl: minWidthMediaQuery(breakpoints.xl),
    large: minWidthMediaQuery(breakpoints.large),
    medium: minWidthMediaQuery(breakpoints.medium),
    small: minWidthMediaQuery(breakpoints.small),
    xs: minWidthMediaQuery(breakpoints.xs),
  },
};

const grid = {
  margin: {
    xsmall: spacing[2.25],
    small: spacing[2.25],
    medium: spacing[4],
    large: spacing[4],
    xlarge: spacing[4],
  },
  columns: {
    xsmall: `
      grid-template-rows: auto;
      grid-template-columns: repeat(2, 1fr);
      column-gap: ${spacing[1]};
    `,
    small: `
      grid-template-rows: auto;
      grid-template-columns: repeat(2, 1fr);
      column-gap: ${spacing[1]};
    `,
    medium: `
      grid-template-rows: auto;
      grid-template-columns: repeat(6, 1fr);
      column-gap: ${spacing[1]};
    `,
    large: `
      grid-template-rows: auto;
      grid-template-columns: repeat(12, 1fr);
      column-gap: ${spacing[2]};
    `,
    xlarge: `
      grid-template-rows: auto;
      grid-template-columns: repeat(12, 1fr);
      column-gap: ${spacing[2]};
    `,
  },
};

const radius = {
  btn: spacing[5.25],
  sm: spacing[1],
  std: spacing[1.25],
};

const typography = {
  fontSize: {
    superTitle: '3rem',
    headingOne: '2.25rem',
    headingTwo: '2rem',
    headingThree: '1.5rem',
    headingFour: '1.125rem',
    headingFive: '0.875rem',
    bodyLg: '1.25rem',
    bodyMd: '1rem',
    bodySm: '0.875rem',
    bodyXs: '0.75rem',
    eyebrow: '1rem',
    button: '1.125rem',
    desktop: {
      superTitle: '4.5rem',
      headingOne: '3.5rem',
      headingTwo: '2.5rem',
      headingThree: '2rem',
      headingFour: '1.5rem',
      headingFive: '1.125rem',
      bodyLg: '1.5rem',
      bodyMd: '1.125rem',
      bodySm: '1rem',
      bodyXs: '0.75rem',
      eyebrow: '1.25rem',
      button: '1.5rem',
    },
  },
  fontWeight: {
    superTitle: '700',
    headingOne: '700',
    headingTwo: '600',
    headingThree: '600',
    headingFour: '600',
    headingFive: '600',
    body: '400',
    eyebrow: '700',
    link: '600',
    button: '600',
  },
  lineHeight: {
    superTitle: '1.2',
    headingOne: '1.2',
    headingTwo: '1.2',
    headingThree: '1.2',
    headingFour: '1.2',
    headingFive: '1.2',
    body: '1.4',
    eyebrow: '1.1',
    link: '1.4',
    button: '1.1',
  },
  letterSpacing: {
    eyebrow: '1px',
  },
};

export const tokens = {
  colors,
  colorSchemes,
  gradients,
  breakpoints,
  mq,
  grid,
  typography,
  spacing,
  radius,
};

export const COLOR_LIST = Object.keys(colors);
